import * as React from "react";
import {Layout} from "./src/components/layout/Layout";
import type { GatsbyBrowser } from "gatsby";
import "./src/styles/global.scss";
import { Location } from '@reach/router';

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location, prevLocation }) => {
  return location.pathname;
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element
}) => {
  return (
      <Layout>
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NVDNLLK"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }</noscript>
        {element}
      </Layout>
  )
}