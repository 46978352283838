import * as React from "react";
import { Helmet } from "react-helmet";

export const CustomHead = () => {
  return (
    <Helmet>
      <title>Life Without A Doctor</title>
      {/* google tag manager script */}
      <script>{
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NVDNLLK');`
      }</script>
      {/* google tag manager no-js */}
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NVDNLLK"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      }</noscript>
      {/* end of google tag manager */}
    </Helmet>
  )
}