import { Link } from "gatsby";
import * as React from "react";
import { Location } from '@reach/router';
import { cnb } from 'cnbuilder';
import icon from "../../images/icon.png";
import iconInverse from "../../images/icon-inverse.svg";
import burger from "../../images/Navigation/menu/24@3x.svg";
import close from "../../images/Navigation/close/24@2x.png";
import "./Header.scss";

export const Header = () => {
  // scrolls detection
  const [scrollPosition, setScrollPosition]=  React.useState<number>(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  }
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    // terminate listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  },[]);

  // toggle mobile menu
  const [showMenu, setShowMenu] = React.useState(false);



  return(
    <Location>
      {(locationContext) => (
      <div className={cnb("nav", {['nav--offHome']: !(locationContext.location.pathname === "/")}, {['nav--isScrolled']: (scrollPosition !== 0)})}>
        <div className="mobile-menu">
          <button onClick={() => setShowMenu(!showMenu)}>
            {!showMenu ? (<img src={burger}/>) : (<img src={close}/>)}
          </button>
        </div>
        <div className="nav__logo">
          <a href="/">
            <img src={!((scrollPosition === 0) && (locationContext.location.pathname === "/")) ? icon : iconInverse}/>
          </a>
        </div>
        <div className={
          cnb(
            "menu", 
            {["menu--visible"]: showMenu }
          ) 
        }>
          <Link 
            className={cnb("menu__item", {['menu__item--isActive']: (locationContext.location.pathname === "/about")} )} 
            to="/about"
          >
            <span>About us</span>
          </Link>
          <Link 
            className={cnb("menu__item", {['menu__item--isActive']: (locationContext.location.pathname === "/findADoctor")} )} 
            to="/findADoctor"
          >
            <span>Find a family doctor</span>
          </Link>
        </div>
      </div>
      )}
    </Location>
  );
};