import { Link } from "gatsby";
import * as React from "react";
// import twitter from "../../images/Navigation/footer/Twitter@2x.png";
// import linkedIn from "../../images/Navigation/footer/LinkedIN@2x.png";
// import college from "../../images/Navigation/footer/Bitmap@2x.png";
import twitter from "../../images/Navigation/footer/Twitter@2x.png";
import linkedIn from "../../images/Navigation/footer/LinkedIN@2x.png";
import facebook from "../../images/Navigation/footer/Facebook.png";
import college from "../../images/Navigation/footer/Bitmap@2x.png";
import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="container container__primary">
        <div className="college">
          <a href="https://www.ontariofamilyphysicians.ca/" target="_blank" rel="noopener noreferrer">
            <img src={college}/>
          </a>
        </div>
        <div className="container__links">
          <p>Follow Us:</p>
          <a href="https://www.linkedin.com/company/ocfp/" target="_blank" rel="noopener noreferrer"><img src={linkedIn} /></a>
          <a href="https://twitter.com/OntarioCollege" target="_blank" rel="noopener noreferrer"><img src={twitter} /></a>
          <a href="https://www.facebook.com/OntarioCollegeFPs/" target="_blank" rel="noopener noreferrer"><img src={facebook} /></a>
        </div>
      </div>
      <div className="container container__secondary">
        <div>
          <small>© 2022 Ontario College of Family Physicians.</small><br/>
          <small>A Chapter of the College of Family Physicians of Canada</small>
        </div>
      </div>
    </div>
  );
};