import * as React from "react";
import { Location } from '@reach/router';
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./Layout.scss";
import { cnb } from "cnbuilder";
import { CustomHead } from "../helmet/CustomHead";

type props ={ 
  children: React.ReactNode;
}

export const Layout = ({children}:props) => {
  return (
    <div className="layout">
      <CustomHead />
      <Header />
      <Location> 
        {(locationContext) => (
          <div className={cnb("layout__body", {['layout__body--offHome']: (locationContext.location.pathname !== "/")} )}>
            {children}
          </div>        
        )}
      </Location>
      <Footer />
    </div>
  );
};